/* main content top */
.main-content-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    background-color: rgb(255, 255, 255);
    height: 5%;
  }
  .sidebar-toggler {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }
  .sidebar-toggler img {
    width: 20px;
  }
  .content-top-title {
    color: rgb(0, 0, 0);
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 500;
  }
  .content-top-btn {
    margin-left: 18px;
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content:end;
    background-color: rgb(255, 255, 255);
  }
  .content-top-btn img {
    width: 24px;
  }
  .content-top-btn img:hover {
    filter: brightness(0) invert(1);
  }
  .content-top-left {
    display: flex;
    align-items: center;
  }

  .icon-2{
    color: black;
    transition: .3s;
  }

  .logout{
    /* color: rgb(0, 0, 0); */
    font-size: 0.9vw;
    font-weight: 500;
    font-family: sans-serif;
    letter-spacing: 1.5px;
    /* transition: .3s; */
    margin-right: 5px;
  }

  .logout-btn{
    /* border-color: black;
    border-style: solid; */
    color: rgb(61, 61, 61);
    /* background: linear-gradient(180deg, #f05f5f, #b33030 135%); */
    background-color: rgb(235, 235, 235);
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-width: 1.5px; */
    border-radius: 5px;
    /* background-color: rgb(255, 255, 255); */
    transition: .3s;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .logout-btn:hover{
    /* border-color: blue;

    .logout{
      color: blue;
    }

    .icon-2{
      color: blue;
    } */
    color: #543e93;
  }

/* @media screen and (max-width: 1400px) {
  .icon-1{
    display:none;
  }
} */

@media screen and (max-width: 1200px) {
  .logout{
    display: none;
  }

  .logout-btn{
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}