.main-content2 {
    background-color:#ffffff;
    flex: 1;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    padding: 32px;
  }

  @media screen and (max-width: 1400px) {
    .main-content {
      padding-left: 20px;
      padding-right: 20px;
    }
    
  }
  

  @media screen and (max-width: 1200px) {
    .main-content {
      padding-right: 16px;
      padding-left: 16px;
    }
  }