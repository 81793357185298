*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
    font-family: sans-serif;
}
.container{
    width: 100%;
    margin: 1.5%;
    /* padding: 0 20px; */
}

.buttonHolder3{
    display: flex;
    /* justify-content: center; */
}

.inpu1 {
    width:fit-content;
    font-size: 0.8rem;
    text-align: center;   
}

.inpu1::placeholder {
    color: #3c354e;
    font-weight: bold; 
    font-size: 0.8rem;
    text-align: center;
}

.inpu2 {
    width:fit-content;
    font-size: 0.8rem;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 10px;
    height: 3rem;
    margin-top: 1.5rem;
    border-width: 2px;
    border-style: solid;
    border-color: #aeaeae;
    color: #3c354e;
    font-weight: bold;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    
}

.input-Container{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: center;
}

.btn4{
    border: none;
    outline: none;
    /* background: linear-gradient(180deg, #f05f5f, #b33030 135%); */
    background-color: #543e93;
    color: #fff;
    border-radius: 5px;
    width: 12em;
    aspect-ratio: 4/1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 20px; */
    margin-top: 20px;
    cursor: pointer;
    transition: .3s;
    border: 1px solid #543e93;
    align-self: stretch;
    font-size: 0.9em;
}

section{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1{
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    margin-bottom: 40px;
}

.cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.ruler{
    width: 100%;
    height: 0.3rem;
    border-radius: 0.8rem;
    border: none;
    margin: 3rem 0 1rem 0;
    /* background: linear-gradient(to right, #14163c 0%, #03217b 79%); */
    background-color: #575757;
    backdrop-filter: blur(25px);
}

.msg-1{
    font-size: 0.9em;
    /* margin-top: 20px; */
    font-weight: 500;
    color: rgb(40, 40, 40);
    margin-left: 1em;
    color: white;
    /* justify-content: center; */
    /* background-color: aquamarine; */
}

.msgContaner-1{
    display:flex;
    padding: 10px;
    flex-direction: row;
    background-color: #878787;
    align-items: center;
    border-radius: 10px;
    /* max-width: 100%; */
    /* align-items: center; */
    /* width: 100%; */
}

.msgContainer-2{
    display:flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #b01c1c; */
    /* justify-content: center; */
    /* align-items: center;
    border-radius: 10px; */
    /* align-items: center; */
    /* width: 100%; */
}

.msgContainer-3{
    display:flex;
    background-color: #97ae57;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px;
    /* margin-right: 10px; */
    /* background-color: #b01c1c; */
    /* justify-content: center; */
    /* align-items: center;
    border-radius: 10px; */
    /* align-items: center; */
    /* width: 100%; */
}

.textContainer-1{
    display: flex;
    /* justify-content: center; */
}

.card{
    flex: 0 0 calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
    width: 100%;
    /* background:#fae3d2; */
    /* background:#96ae53; */
    background:#97ae57;
    color: white;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    transition: .3s;
    border: 2px solid transparent;
}

.image-1{
    display: flex;
    /* background-color: blueviolet; */
    /* justify-content: center; */
}

.image-2{
    display: flex;
    /* background-color: blueviolet; */
    /* justify-content: center; */
}

.img-1{
    width: 2.2em;
    height: 2.2em;
    /* display: flex; */
    /* justify-content: center; */
    
}

.img-2{
    width: 7rem;
    /* height: 7em; */
    margin-top: 40px;
    /* display: flex; */
    /* justify-content: center; */
    
}

.tex-1 {
    /* font-size: 1.1em; */
    font-size: 1.3em;
    font-weight: 500;
    margin-top: 20px;
    text-align: center;
    color: #575757;
}

.tex-2 {
    font-size: 1.1em;
    font-weight: 500;
    margin-top: 25px;
    color: #575757;
}

.tex-3 {
    /* font-size: 1.1em; */
    font-size: 1.1em;
    font-weight:bold;
    margin-top: 20px;
    text-align: center;
    color: #3c354e;
    /* color: #ffffff; */
}

.tex-4 {
    /* font-size: 1.1em; */
    font-size: 1em;
    font-weight: 500;
    text-align: center;
    color: #3c354e;
    /* color: #ffffff; */
}

.error-tex {
    font-size: 1em;
    font-weight: 400;
    margin-top: 10px;
    color: rgb(167, 42, 20);
    text-align: center;
}

.card:hover{
    border-color: #543e93;
}

.btn{
    border: none;
    outline: none;
    background: #543e93;
    color: #fff;
    border-radius: 10px;
    max-width: 160px;
    /* max-width: 120px; */
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    transition: .3s;
    border: 1px solid #1d39c4;
}

.btn:hover{
    background: transparent;
    color: #543e93;
}

.btn4:hover{
    transition: .3s;
    background: transparent;
    color: #543e93;
}

@media(max-width: 969px){
    section{
        padding: 20px 0px;
    }
    h1{
        font-size: 30px;
        line-height: 40px;
    }
    .card{
        flex: 0 0 calc(50% - 20px);
        max-width: calc(50% - 20px);
        
    }
}

@media(max-width: 600px){
   
    .card{
        flex: 0 0 100%;
        max-width: 100%;
        
    }
}