@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");

.styledbutton {
/* background: linear-gradient(to right, #14163c 0%, #03217b 79%); */
background: linear-gradient(to right, #543e93 0%, #725bb1 79%);
text-transform: uppercase;
letter-spacing: 0.2rem;
/* width: 65%; */
width: 80%;
height: 3rem;
border: none;
color: white;
/* border-radius: 2rem; */
border-radius: 10px;
align-items: center;
display: flex;
justify-content: center;
cursor: pointer;
font-family: 'Raleway', sans-serif;
font-weight: bold;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.body-container {
    width: 100%;
    height: 100%;
    background-image: url("./bg2.png");
    /* background-size:cover; */
    background-size:contain;
    /* background: linear-gradient(180deg, #f05f5f, #b33030 135%); */
    /* background: linear-gradient(180deg, #7159b0, #d0d0d0 135%); */
    /* background-color: rgb(224, 224, 224); */
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    height: 100vh;
    width: 100vw;
    font-family: "Raleway", sans-serif;
}

.error1 {
 font-size: 0.8rem;
 color: rgb(238, 73, 73);
}

.imagge-2{
    width: 8em;
    height: 8em;
    /* display: flex; */
    /* justify-content: center; */
}

.imgr2{
    display: flex;
    margin-top: 3rem;
    margin-bottom: 1.9rem;   
}

.text-2 {
    font-size: 0.8rem;
    font-weight: 600;
    font-family:sans-serif;
    color: #7d7d7d;

}

.abc {
    text-align: center;
    padding-left: 6%;
    padding-right: 6%;
    margin-bottom: 3rem;
}

.link-1 {
    font-size: 0.8rem;
    font-weight: 600;
    font-family:sans-serif;
    color: #2e4b8a;
    text-decoration: underline;
}

.text-3 {
    font-size: 0.8rem;
    font-weight: 600;
    font-family:sans-serif;
    color: #7d7d7d;
}

.error-container {
    display: flex;
    width: 73%;
    justify-content: start;
}

input{background: rgba(255, 255, 255, 0.15);
    /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
    /* border-radius: 2rem; */
    border-radius: 10px;
    /* box-shadow: 0 0 0 2px #d6d5d5; */
    
    width: 80%;
    height: 3rem;
    padding: 1rem;
    margin-top: 1.5rem;
    border-width: 2px;
    border-style: solid;
    border-color: #aeaeae;
    /* border: none; */
    /* outline: none; */
    /* outline-width: 5px; */
    color: #3c354e;
    font-size: 1rem;
    font-weight: bold;
    /* &:focus{
        display: inline-block;
        box-shadow: 0 0 0 0.2rem #b6b6b8;
        backdrop-filter: blur(12rem);
        border-radius: 10px;
    } */
    &::placeholder{
        color:#808080;
        font-weight: 100;
        font-size: 1rem;
    }


}