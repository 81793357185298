.styledbuttonn {
    background: linear-gradient(to right, #14163c 0%, #03217b 79%);
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    width: 65%;
    height: 3rem;
    border: none;
    color: white;
    border-radius: 2rem;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
}

.abcd {
    text-align: center;
    padding-left: 6%;
    padding-right: 6%;
    margin-bottom: 1rem;
}

.body-containerr {
    width: 100%;
    height: 100%;
    background-image: url("./bg2.png");
    background-size:contain;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.imagge-3{
    width: 7em;
    height: 7em;
    /* display: flex; */
    /* justify-content: center; */
}

.imgr3{
    display: flex;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.text-4 {
    font-size: 0.8rem;
    font-weight: 600;
    font-family:sans-serif;
    color: #7d7d7d;
}

.link-2 {
    font-size: 0.8rem;
    font-weight: 600;
    font-family:sans-serif;
    /* color: #0a2358; */
    color: #2e4b8a;
    text-decoration: underline;
}

.error-containerr {
    display: flex;
    width: 73%;
    justify-content: start;
}

.error11 {
    font-size: 0.8rem;
    font-family: sans-serif;
    font-weight: 500;
    color: red;
}