*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
    font-family: sans-serif;
}

.image{
    display: flex;
    justify-content: center;
}

.modal {
    font-size: 1.2rem;
  }
  .modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
  }
  .modal > .content {
    display: flex;
    width: 100%;
    padding: 10px 5px;
    justify-content: center;
    text-align: center;
  }
  .modal > .actions {
    display: flex;
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
    flex-direction:row;
    justify-content:space-evenly;
  }
  .modal > .actions2 {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 10px 5px;
    text-align: center;

    
  }

  .modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }

img{
    width: 10%;
    height: 10%;
    display: flex;
    justify-content: center;
}
.container2{
    /* max-width: 1300px; */
    width: 100%;
    margin: 1.5%;
    padding: 0 20px;
}

.centerText{
    display: flex;
    justify-content: center;
    align-items: center;
}

.ortext {
    margin-top: 10px;
}
.paymentlogoholder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
    margin-bottom: 1%;
}

.paymentlogoholder h4 {
    font-size: 1rem;
    margin-right: 7px;
}

.paymentLogo {
    width: 5rem;
    aspect-ratio: 200/44;
}

.videocontainer {
    max-width: 600px; /* Optional: Set a maximum width for the container */
    margin: 0 0; /* This centers the container horizontally */
    position: relative;
    padding-bottom: 20px;
  }

.reviewContainer{
    display: flex;
    flex-direction: column;
    background-color: #f1eff9;
    padding-left: 2rem;
    padding-right: 2rem;
    /* max-width: 85%; */
    width: 97%;
    align-items: center;
    border-radius: 10px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.reviewContainertop{
    background-color: #EFF1F9;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.reviewContainertop h2{
    color: rgb(58, 58, 58);
    font-size: 1.1rem;
}

.reviewContainertop h4{
    color: rgb(52, 52, 52);
    margin-top: 3px;
}

.reviewContainer p{
    color: rgb(22, 22, 22);
    margin-bottom: 0.5rem;
    text-align: center;
    font-size: 1.1rem;
}

section{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
}

h1{
    font-size: 43px;
    line-height: 60px;
    text-align: start;
    margin-bottom: 25px;
}

h3{
    font-size: 20px;
    
    
}

.cards2{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
}

.iconn{
    margin-right: 10px;
}

.holder1{
    display: flex;
    flex-direction: column;
    padding-left: 20px;
}

.holder2{
    display: flex;
    padding-left: 20px;
}

.holder3{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    
}

.buttonHolder{
    display: flex;
    padding-left: 20px;
    
}

.buttonHolder2{
    display: flex;
    justify-content: center;
}

.card2{
    flex: 0 0 calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    transition: .3s;
    border: 2px solid transparent;
}

.popupbutton1{
    border: none;
    outline: none;
    background: #543e93;
    color: #fff;
    border-radius: 5px;
    min-width: 30%;
    max-width: 70%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    transition: .3s;
    border: 1px solid #543e93;
    align-self: stretch;
    font-size: 1.2rem;
    padding-left: 5px;
    padding-right: 5px;

}

.popupbutton3{
    border: none;
    outline: none;
    background: #543e93;
    color: #fff;
    border-radius: 5px;
    min-width: 40%;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    transition: .3s;
    border: 1px solid #e2e2e2;
    align-self: stretch;
    font-size: 1.2rem;
    align-self: center;
    padding: 5px;
}

/* .popupbutton2{
    border: none;
    outline: none;
    background: #e2e2e2;
    color: #151515;
    border-radius: 5px;
    width: 30%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    transition: .3s;
    border: 1px solid #e2e2e2;
    align-self: stretch;
    font-size: 1.2rem;
} */

.popupbutton2{
    border: none;
    outline: none;
    background: #e2e2e2;
    color: #151515;
    border-radius: 5px;
    width: 40%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    transition: .3s;
    border: 1px solid #e2e2e2;
    align-self: stretch;
    font-size: 1.2rem;
    align-self: center;
}

.btn2{
    border: none;
    outline: none;
    background: #543e93;
    color: #fff;
    border-radius: 10px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    transition: .3s;
    border: 1px solid #543e93;
    align-self: stretch;
}

.btn3{
    border: none;
    outline: none;
    background: #1d39c4;
    color: #fff;
    border-radius: 5px;
    /* width: 15%; */
    width: 15em;
    aspect-ratio: 5/1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    transition: .3s;
    border: 1px solid #1d39c4;
    align-self: stretch;
}

.btnn {
    border: none;
    outline: none;
    background: #543e93;
    color: #fff;
    border-radius: 5px;
    /* width: 15%; */
    width: 15em;
    aspect-ratio: 5/1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    transition: .3s;
    border: 1px solid #1d39c4;
    align-self: stretch;
    align-self: center;
}

.btnalign {
    display: flex;
    width: 100%;
    justify-content: center;
}

.packActivation{
    width: 100%;
    height: 50vh;
    background-color: white;
}

.packAct-cont{
    display: flex;
    justify-content: center;
    margin-top: 2%;
}

.text-activation{
    font-family: sans-serif;
    font-size: 1.2em;
    font-weight: 200;
    color: rgb(40, 40, 40);
}

.text-activation-2{
    font-family: sans-serif;
    font-size: 1.2em;
    font-weight: 200;
    color: rgb(40, 40, 40);
}

.text-activation-3{
    font-family: sans-serif;
    /* font-size: 1.3em; */
    font-size: 1.4em;
    font-weight: 600;
    color: rgb(40, 40, 40);
    margin-bottom: 1%;
    text-align: center;
}

.btn2:hover{
    background: transparent;
    color: #543e93;
}

.btn3:hover{
    background: transparent;
    color: #1d39c4;
}

.btnn:hover{
    background: transparent;
    color: #543e93;
}

.popupbutton1:hover{
    background: transparent;
    color: #543e93;
}

.popupbutton2:hover{
    background: transparent;
    color: #000000;
}

@media(max-width: 969px){
    section{
        padding: 0px 0px;
    }
    h1{
        font-size: 30px;
        line-height: 40px;
    }
    .card2{
        flex: 0 0 calc(50% - 20px);
        max-width: calc(50% - 20px);
        
    }
}

@media(max-width: 600px){
   
    .card2{
        flex: 0 0 100%;
        max-width: 100%;
        
    }
}