* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .imgL {
    max-width: 100%;
    height: auto;
  }

  .immgL {
    width: 5em;
    height: 5em;
  }

  .imgrr {
    padding: 0.5rem;
  }

  .imL {
    width: 3em;
    height: 3em;
  }

  .AppL {
    min-height: 100vh;
    /* width: 85vw;
    max-width: 1900px;
    margin: 0rem auto; */
  }
  body {
    max-width: 100%;
    overflow-x: hidden;
  }
  
  body {
    background-color: #f6f6f6;
  }
  
  .nav1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 90px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .navbar-menu-containerL {
    display: none;
  }
  .navbar-links-containerL a {
    margin-right: 3rem;
    text-decoration: none;
    color: black;
    font-size: 1.1rem;
    font-weight: 600;
  }
  .primary-buttonL {
    padding: 0.9rem 1.75rem;
    background-color: white;
    outline: none;
    border: none;
    border-radius: 5rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    transition: 0.2s;
  }
  .navbar-cart-iconL {
    font-size: 1.15rem;
  }
  .primary-buttonL:hover {
    background-color: rgb(234, 234, 234);
  }
  .navbar-menu-containerL svg {
    font-size: 1.5rem;
    cursor: pointer;
  }
  .home-banner-containerL {
    position: relative;
    display: flex;
    padding-top: 6rem;
    background-image: url("./bg32.png");
    background-size:cover;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 6rem;
  }
  .home-bannerImage-containerL {
    position: absolute;
    top: -100px;
    right: -170px;
    z-index: -2;
    max-width: 700px;
  }
  .home-image-sectionL {
    max-width: 600px;
    flex: 1;
  }
  .home-text-sectionL {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  
  .primary-headingL {
    font-size: 1.8rem;
    color: #4c4c4c;
    /* line-height: 5rem; */
  }

  .primary-headingL2 {
    font-size: 1.9rem;
    color: rgb(63, 58, 100);
    /* line-height: 5rem; */
  }

  .primary-headingL3 {
    font-size: 1.9rem;
    /* font-size: 1.9rem; */
    color: rgb(63, 58, 100);
    max-width: 950px;
    /* line-height: 5rem; */
  }
  .primary-textL {
    font-size: clamp(1rem, 3vw, 1.5rem);
    max-width: 900px;
    color: #6a6a6a;
    margin: 1.5rem 0rem;
  }
  .primary-textL2 {
    font-size: 1.4;
    max-width: 500px;
    color: #6a6a6a;
    margin: 1.5rem 0rem;
  }
  .secondary-buttonL {
    padding: 1rem 2.5rem;
    background-color: #543E93;
    outline: none;
    border: none;
    border-radius: 5rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    color: white;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .secondary-buttonL svg {
    margin-left: 0.75rem;
    font-size: 1.5rem;
  }
  .secondary-buttonL:hover {
    background-color: #3c2774;
  }
  .about-section-containerL {
    margin-top: 20rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .about-background-image-containerL {
    position: absolute;
    left: -150px;
    z-index: -2;
  }
  .about-section-image-containerL {
    flex: 0.9;
    margin-right: 1rem;
  }
  .about-section-text-containerL {
    flex: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .primary-subheadingL {
    font-weight: 700;
    color: #fe9e0d;
    font-size: 1.15rem;
  }
  .about-buttons-containerL {
    margin-top: 2rem;
    display: flex;
  }
  .watch-video-buttonL {
    margin-left: 2rem;
    background-color: transparent;
    outline: none;
    border: none;
    border-radius: 5rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    color: #484848;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .watch-video-buttonL svg {
    font-size: 3rem;
    margin-right: 1rem;
  }
  .contact-page-wrapperL h1 {
    max-width: 900px !important;
  }
  .contact-page-wrapperL {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 6rem 0rem;
  }
  .contact-form-containerL {
    background-color: white;
    max-width: 700px;
    width: 100%;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 5rem;
  }
  .contact-form-containerL input {
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
  }
  .footer-wrapperL {
    margin: 6rem 0rem;
    display: flex;
    margin-top: 10rem;
  }
  .footer-logo-containerL {
    max-width: 110px;
  }
  .footer-iconsL {
    margin-top: 2.5rem;
  }
  .footer-iconsL svg {
    font-size: 1.5rem;
    margin-right: 1.25rem;
    color: #585858;
  }
  /* .footer-section-one {
    border: 2px solid blue;
  } */
  .footer-section-twoL {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .footer-section-columnsL {
    display: flex;
    flex-direction: column;
    min-width: 190px;
  }
  .footer-section-columnsL span {
    margin: 0.25rem 0rem;
    font-weight: 600;
    color: #4c4c4c;
    cursor: pointer;
  }
  .testBottom{
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding-left: 8%;
    padding-right: 8%;
    padding-bottom: 5rem;
  }

  .testBottom p {
    margin-bottom: 2rem;
    text-align: center;
    font-size: 1.3rem;
    color: rgb(120, 129, 148);
    
  }
  .testimonial-section-bottomL {
    margin-top: 2rem;
    background-color: rgb(255, 255, 255);
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 750px;
    border-radius: 2rem;
    flex-direction: column;
    text-align: center;
  }
  .testimonial-section-bottomL {
    margin: 2rem auto;
  }
  .testimonial-section-bottomL p {
    font-weight: 700;
    color: #515151;
    font-size: 1.1rem;
  }
  .testimonials-stars-containerL svg {
    margin: 0rem 0.25rem;
    font-size: 1.5rem;
    color: #fe9e0d;
  }
  .testimonial-section-bottomL h2 {
    margin-top: 1.5rem;
  }
  .work-section-wrapperL {
    padding-top: 3rem;
    background-color: #EFF1F9;
  }

  .work-section-wrapperL2 {
    padding-top: 3rem;
    background-color: #ffffff;
  }

  .work-section-topL p {
    text-align: center;
    max-width: 600px !important;
  }
  .work-section-topL h1 {
    max-width: 700px !important;
  }
  .work-section-topL {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .work-section-infoL {
    width: 350px;
    background-color: white;
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    text-align: center;
    border-radius: 1rem;
    color: rgb(80, 80, 80);
    margin: 1rem 2rem;
  }
  .work-section-infoL h2 {
    margin: 1rem 0rem;
  }
  .work-section-bottomL {
    margin-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .work-section-infoL p {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: rgb(120, 129, 148);
  }
  .video-container {
    width: 80%;
    max-width: 600px; /* Optional: Set a maximum width for the container */
    margin: 0 0; /* This centers the container horizontally */
    position: relative;
    padding-bottom: 20px;
  }
  
  
  @media (max-width: 1000px) {
    .navbar-links-containerL a {
      margin-right: 1rem;
      font-size: 1rem;
    }
    .primary-buttonL {
      font-size: 1rem;
    }
    .home-bannerImage-containerL {
      max-width: 600px;
    }
  }
  @media (max-width: 800px) {
    .nav-logo-containerL {
      max-width: 140px;
    }
    .navbar-links-containerL {
      display: none;
    }
    .navbar-menu-containerL {
      display: flex;
    }
    .home-bannerImage-containerL {
      max-width: 450px;
    }
    .home-banner-containerL{
      padding-top: 3rem;
      padding-bottom: 3rem;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }
    .about-section-containerL {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }
    .about-section-containerL {
      flex-direction: column;
    }
    .home-image-sectionL,
    .about-section-image-containerL {
      width: 100%;
      max-width: 400px;
    }
    .primary-headingL {
      text-align: center;
      max-width: 90%;
    }
    .primary-headingL2 {
      text-align: center;
      max-width: 90%;
      font-size: 1.5rem;
    }

    .primary-headingL3 {
      text-align: center;
      max-width: 90%;
      font-size: 1.5rem;
      /* line-height: 5rem; */
    }
    .primary-textL {
      font-size: 1.3rem;
      text-align: center;
      max-width: 80%;
    }
    .home-text-sectionL {
      justify-content: center;
      align-items: center;
    }
    .about-section-text-containerL {
      justify-content: center;
      align-items: center;
      margin-top: 4rem;
    }
    .secondary-buttonL {
      font-size: 1rem;
      padding: 0.8rem 2rem;
      cursor: pointer;
    }
    .about-section-containerL {
      margin-top: 5rem !important;
    }
    .about-buttons-containerL {
      justify-content: center;
      flex-direction: column;
    }
    .primary-subheadingL {
      text-align: center;
    }
    .watch-video-buttonL {
      margin-left: 0rem !important;
      margin-top: 1rem;
      font-size: 1rem !important;
    }
    .watch-video-buttonL svg {
      margin-right: 0.5rem !important;
    }
    .about-section-image-containerL {
      margin-right: 0rem !important;
    }
    /* .work-section-wrapperL {
      margin-top: 5rem !important;
    } */
    .work-section-bottomL {
      margin-top: 1rem !important;
    }
    .contact-form-containerL {
      padding: 0.5rem !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 1rem !important;
    }
    .contact-form-containerL input {
      font-size: 1rem !important;
      max-width: 100%;
      padding: 0.5rem 1rem !important;
      margin-bottom: 0.8rem !important;
      text-align: center;
    }
    .footer-wrapperL {
      flex-direction: column;
    }
    .footer-section-twoL {
      flex-direction: column;
      margin-top: 2rem;
    }
    .footer-section-columnsL {
      margin: 1rem 0rem;
    }
    .AppL {
      max-width: 95vw;
    }
    .work-section-topL {
      padding-left: 8%;
      padding-right: 8%;
    }
    .testBottom p {
      font-size: 1.2rem;
    }
  }
  @media (max-width: 600px) {
    .home-bannerImage-containerL,
    .about-background-image-containerL {
      display: none;
    }
  }

