.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%; /* This ensures a 16:9 aspect ratio */
    position: relative;
    height: 0; /* Important: Keeps the container height calculated by the padding-bottom */
  }
  
  .video-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  