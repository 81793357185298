.main-content-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  }
  .sidebar-toggler {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }
  .sidebar-toggler img {
    width: 20px;
  }
  .content-top-title-2 {
    color: rgb(37, 37, 37);
    font-size: 20px;
    font-weight: 500;
  }
  .content-top-btn {
    margin-left: 18px;
  }
  .content-top-btn img {
    width: 24px;
  }
  .content-top-btn img:hover {
    filter: brightness(0) invert(1);
  }
  .content-top-left {
    display: flex;
    align-items: center;
  }

  .logout-2{
    color: rgb(0, 0, 0);
    font-size: 0.9vw;
    font-weight: 500;
    font-family: sans-serif;
    letter-spacing: 1.5px;
    transition: .3s;
    margin-right: 5px;
  }

  .logout-btn-2{
    border-color: black;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1.5px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    transition: .3s;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .logout-btn-2:hover{
    border-color: blue;

    .logout-2{
      color: blue;
    }
}

@media screen and (max-width: 1200px) {
  .logout-2{
    display: none;
  }

  .logout-btn-2{
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}